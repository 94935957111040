import { menuRoutes } from '../../../services/routes';
import DesktopMenuLink from './DesktopMenuLink';
import './index.css';
import Banner from '../Banner';

function HeaderDesktop() {
    const routes = menuRoutes();
    return (
        <div className="row">
            <div className="col full-width">
                <Banner />
                <div className="row navigation mt-m">
                    {routes
                        .filter((route) => route.name)
                        .map((route, i) => (
                            <DesktopMenuLink key={i} {...route} />
                        ))}
                </div>
            </div>
        </div>
    );
}

export default HeaderDesktop;
