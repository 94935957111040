import Home from '../screens/Home';
import Gite from '../screens/Gite';
import Contact from '../screens/Contact';
import Pricing from '../screens/Pricing';
import Legals from '../screens/Legals';
import Activities from '../screens/Activities';

export const routes = [
    {
        path: '/accueil',
        element: <Home />,
        name: 'Accueil'
    },
    {
        path: '/gite',
        element: <Gite />,
        name: 'Le gîte'
    },
    {
        path: '/activities',
        element: <Activities />,
        name: 'Activités / Tourisme'
    },
    {
        path: '/tarifs',
        element: <Pricing />,
        name: 'Tarifs'
    },
    {
        path: '/contact',
        element: <Contact />,
        name: 'Contact'
    },
    {
        path: '/mentions_legales',
        element: <Legals />,
        name: 'Mentions légales',
        hide: true
    },
    {
        path: '*',
        element: <Home />,
        hide: true
    }
];
export function getNameOfPath(path) {
    const filteredRoutes = routes.filter((r) => r.path === path);
    if (filteredRoutes.length > 0) {
        return filteredRoutes[0].name || '';
    }
    return routes[0].name;
}
export function menuRoutes() {
    return routes.filter((route) => !route.hide);
}
