import ActivityAddress from '../ActivityAddress';
import Link from '../../Misc/Link';
import PropTypes from 'prop-types';
import './index.css';

export default function ActivityText(props) {
    const { activity } = props;
    const { title, text, website, distance } = activity;

    return (
        <div className="col p-s justify-center activity-item-text-container">
            <div className="row justify-center">
                <div className="col full-width">
                    <h2>{title}</h2>
                    {distance ? (
                        <span className="activity-distance-value">À {distance}</span>
                    ) : null}
                    <p
                        dangerouslySetInnerHTML={{
                            __html: text
                        }}
                    />
                    <ActivityAddress activity={activity} />
                    <div className="row mt-s" />
                    {website ? (
                        <Link
                            link={website}
                            text="Voir le site"
                            icon="link"
                            className="fit-content"
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
}
ActivityText.propTypes = {
    activity: PropTypes.object.isRequired
};
