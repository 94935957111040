import Link from '../../Misc/Link';
import PropTypes from 'prop-types';

export default function ActivityAddress(props) {
    const { address, mapsLink } = props.activity;
    if (!address) {
        return null;
    }
    const addressElement = <span>{address}</span>;
    if (mapsLink) {
        return <Link link={mapsLink} text={addressElement} icon="place" className="fit-content" />;
    }
    return address;
}
ActivityAddress.propTypes = {
    activity: PropTypes.object.isRequired
};
