import PropTypes from 'prop-types';
import Icon from '../Icon';
import './index.css';

export default function FeatureIcon(props) {
    const { label, icon } = props;

    return (
        <div className="col feature-icon-container p-m">
            <div className="row justify-center">
                <Icon name={icon} size="xl" color="primary" />
            </div>
            <div className="row mt-s justify-center">{label}</div>
        </div>
    );
}

FeatureIcon.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
};
