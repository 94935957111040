import './index.css';

export default function Loader() {
    return (
        <div className="spinner">
            <div className="double-bounce1" />
            <div className="double-bounce2" />
        </div>
    );
}
