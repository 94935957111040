import React from 'react';
import './index.css';
import MailLink from '../../components/Misc/MailLink';

export default function Pricing() {
    return (
        <React.Fragment>
            <h2 className="mt-s">Tarifs de notre gîte</h2>
            <p>
                Nous vous accueillons dans notre gîte tout au long de l’année.
                <br />
                Les tarifs varient en fonction de la période, de la durée et du nombre de personnes.
                <br />
                En fonction de la période et de la durée du séjour, un acompte pourra vous être
                demandé.
            </p>
            <table className="pricing-table">
                <thead>
                    <tr>
                        <td>
                            <b>Période</b>
                        </td>
                        <td className="text-align-left">
                            <b>Tarif / nuitée</b>
                        </td>
                        <td className="text-align-left">
                            <b>Tarif / semaine</b>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Avril à Juin / Sept à Nov</td>
                        <td>85 €</td>
                        <td>520 €</td>
                    </tr>
                    <tr>
                        <td>Juil et Aout</td>
                        <td>95 €</td>
                        <td>570 €</td>
                    </tr>
                    <tr>
                        <td>Dec à Mars</td>
                        <td>75 €</td>
                        <td>470 €</td>
                    </tr>
                </tbody>
            </table>
            <h3 className="mt-s">Le tarif comprend :</h3>
            <ul>
                <li>Le linge de maison (draps, serviettes de toilette, torchons)</li>
                <li>Les lits sont faits avant votre arrivée</li>
                <li>
                    Un lit bébé (type parapluie) et une chaise haute sont fournis gratuitement, sur
                    demande
                </li>
                <li>Table et fer à repasser (vapeur) ainsi qu’un sèche-cheveux sont disponibles</li>
                <li>L’accès à la machine à laver commune est possible sur demande</li>
                <li>Wifi gratuit</li>
                <li>Place de Parking gratuit dans le jardin clos</li>
            </ul>
            <h3>En supplément :</h3>
            <ul>
                <li>La taxe de séjour</li>
                <li>Forfait ménage de fin de séjour</li>
                <li>
                    Les animaux de compagnie seront acceptés à notre discrétion, et moyennant un
                    supplément.
                </li>
            </ul>
            <p>
                N&apos;hésitez pas à nous contacter pour un devis à <MailLink />
            </p>
            <h3 className="mt-s">Moyens de paiement acceptés</h3>
            <div className="row mt-s space-evenly wrap accepted-payments">
                <div className="col mr-l">
                    <div className="row">
                        <img src="/images/logos/cheque-bancaire.png" alt="Chèque" />
                    </div>
                    <div className="row justify-center">Chèques</div>
                </div>
                <div className="col mr-l">
                    <div className="row">
                        <img src="/images/logos/euros.png" alt="Espèces" />
                    </div>
                    <div className="row justify-center">Espèces</div>
                </div>
                <div className="col mr-l">
                    <div className="row justify-center">
                        <img src="images/logos/ancv_macaron.png" alt="Chèque vacances" />
                    </div>
                    <div className="row justify-center">Chèque vacances</div>
                </div>
            </div>
        </React.Fragment>
    );
}
