import { BrowserRouter as Router } from 'react-router-dom';
import AppHeader from './components/Header';
import MainContainer from './components/MainContainer';
import './App.css';
import ScrollToTop from './components/ScrollToTop';

function App() {
    return (
        <Router>
            <div className="col app">
                <ScrollToTop />
                <AppHeader />
                <MainContainer />
            </div>
        </Router>
    );
}

export default App;
