import React from 'react';
import './index.css';
import FeatureIcon from '../../components/FeatureIcon';
import InformationModal from '../../components/InformationMessage';
import PARTNERS from '../../texts/partners';
import PartnerItem from '../../components/PartnerItem';
import MailLink from '../../components/Misc/MailLink';

export default function Home() {
    return (
        <React.Fragment>
            <div className="row justify-center desktop_only">
                <img
                    alt="Le jardin de Jany"
                    src="/images/gite/pano.jpg"
                    className="home-image"
                    style={{ maxHeight: '500px' }}
                />
            </div>
            <div className="row justify-center mobile_only">
                <img
                    alt="Le jardin de Jany"
                    src="/images/gite/medium/gite-12.jpg"
                    className="home-image"
                    style={{ maxHeight: '500px' }}
                />
            </div>
            <h2 className="mt-s">Bienvenue à Tiffauges</h2>
            <h3 className="mt-m">
                Nous vous accueillons dans notre gîte le Jardin de Jany à Tiffauges, dans le bocage
                vendéen.
            </h3>
            <p>
                Au cœur d&apos;un jardin fleuri et arboré, notre gîte vous accueille toute
                l&apos;année.
                <br />
                Il est conçu pour des séjours confortables et reposants. Il bénéficie du calme de la
                campagne et des services de proximité de la ville.
            </p>
            <p>
                La Commune de Tiffauges, est située{' '}
                <b>au cœur des 3 Provinces (La Loire-Atlantique, Le Maine-et-Loire et La Vendée)</b>
                .
            </p>
            <p>
                À 600m à pieds, vous pourrez découvrir le <b>Château de Tiffauges</b> appelé aussi{' '}
                <b>Château de Barbe-Bleue</b>, ouvert du 1er avril au 30 septembre – Le Marché de
                Noël au Château de Tiffauges les deux premiers WE de Décembre.
            </p>
            <p>Des parcours de randonnées en bord de la Sèvre Nantaise passent près du Gite.</p>

            <InformationModal type="warning">
                <h3>Informations relatives à l&apos;épidémie de covid-19</h3>
                <p>
                    Nous vous restituons une information officielle reçue ce matin, du cabinet
                    secrétariat d’état chargé du Tourisme.
                    <br />
                    En date du <b>04/08/2021</b> : Le pass sanitaire n&apos;est pas obligatoire pour
                    les locations ne proposant qu&apos;une activité d&apos;hébergement.
                </p>
            </InformationModal>

            <h2 className="mt-s">Prestations principales</h2>
            <div className="row space-evenly wrap">
                <FeatureIcon icon="wifi" label="Wifi gratuit" />
                <FeatureIcon icon="local_florist" label="Jardin privatif" />
                <FeatureIcon icon="deck" label="Mobilier de jardin" />
                <FeatureIcon icon="local_parking" label="Parking privatif" />
                <FeatureIcon icon="bed" label="5 places" />
                <FeatureIcon icon="smoke_free" label="Non fumeur" />
            </div>

            <h2 className="mt-m">Animaux domestiques</h2>
            <p>
                Les animaux de compagnie seront acceptés à notre discrétion, et moyennant un
                supplément.
            </p>
            <div className="row wrap space-evenly mt-m">
                {PARTNERS.map((partner, i) => (
                    <PartnerItem key={i} outsideLink={partner} />
                ))}
            </div>
            <p>
                N&apos;hésitez pas à nous contacter pour toute information à l&apos;adresse{' '}
                <MailLink />
            </p>
        </React.Fragment>
    );
}
