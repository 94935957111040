import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export default function FormInput(props) {
    const { label, name, validator, errorMessage, type, placeholder, onChange, onValidityChange } =
        props;

    const [touched, setTouched] = useState(false);
    const [value, setValue] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        onChange(value);
    }, [value]);

    useEffect(() => {
        onValidityChange(error === '' && touched === true);
    }, [error, touched]);

    function handleChange(newValue) {
        setTouched(true);
        if (validator(newValue)) {
            setError('');
        } else {
            setError(errorMessage);
        }
        setValue(newValue);
    }

    return (
        <React.Fragment>
            <div className="row mt-m">
                <label htmlFor={name} className="ml-l">
                    {label}
                </label>
            </div>
            <div className="row mt-s">
                {type === 'input' ? (
                    <input
                        type="text"
                        name={name}
                        placeholder={placeholder}
                        className="contact-form-input"
                        value={value}
                        onChange={(e) => handleChange(e.target.value)}
                        onKeyUp={() => setTouched(true)}
                    />
                ) : (
                    <textarea
                        name={name}
                        placeholder={placeholder}
                        className="contact-form-input"
                        rows="5"
                        value={value}
                        onChange={(e) => handleChange(e.target.value)}
                        onKeyUp={() => setTouched(true)}
                    />
                )}
            </div>
            {error ? <div className="row error-line mt-s">{error}</div> : null}
        </React.Fragment>
    );
}

FormInput.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    validator: PropTypes.func.isRequired,
    errorMessage: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onValidityChange: PropTypes.func.isRequired
};
