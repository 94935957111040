export const PARTNERS = [
    {
        name: 'Tiffauges',
        link: 'https://www.tiffauges.fr',
        logoSrc: '/images/logos/ville_tiffauges.png'
    },
    {
        name: 'Château de Barbe Bleue',
        link: 'https://www.sitesculturels.vendee.fr/Chateau-de-Tiffauges',
        logoSrc: '/images/logos/vendee-sites-culturels.png'
    },
    {
        name: 'Office du tourisme du Pays de mortagne',
        link: 'https://www.paysdemortagne.fr/tourisme-loisirs-culture/tourisme/office-tourisme-pays-mortagne/',
        logoSrc: '/images/logos/pays_de_mortagne.png'
    },
    {
        name: 'Vendée Bocage',
        link: 'https://www.vendeebocage.fr/',
        logoSrc: '/images/logos/vendee-bocage.svg'
    },
    {
        name: 'Vendée coté vacances',
        link: 'https://www.vendee-cote-vacances.com',
        logoSrc: '/images/logos/vendee_cote_vacances.svg'
    },
    {
        name: 'Cybevasion',
        link: 'https://www.cybevasion.fr/',
        logoSrc: '/images/logos/cybevasion.webp'
    }
];

export default PARTNERS;
