import React from 'react';
import './index.css';
import MailLink from '../../components/Misc/MailLink';
import { PHONE_NUMBER, PRINTABLE_PHONE_NUMBER } from '../../commons/const';
import ContactForm from '../../components/ContactForm';

export default function Contact() {
    return (
        <React.Fragment>
            <h2 className="mt-s">Contact</h2>
            <div className="row space-between contact-container mt-m">
                <div className="col map-col">
                    <iframe
                        className="map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2720.370231742884!2d-1.119180749165283!3d47.01333733666217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48066fcac61a1111%3A0x7e0a9f203a4d8e2e!2s5%20Rue%20de%20la%20Crume%2C%2085130%20Tiffauges!5e0!3m2!1sfr!2sfr!4v1624111168806!5m2!1sfr!2sfr"
                        allowFullScreen=""
                        loading="lazy"
                        title="Le jardin de Jany"
                    />
                </div>
                <div className="col info-col">
                    <b>Le jardin de Jany</b>
                    <br />
                    5, Rue de la Crûme
                    <br />
                    85130 Tiffauges
                    <br />
                    <a href={`tel:${PHONE_NUMBER}`}>{PRINTABLE_PHONE_NUMBER}</a>
                    <MailLink />
                    <br />
                    <br />
                    Pour accéder au Jardin de Jany :<br />
                    <ul>
                        <li>depuis Montaigu et Cholet par la Départementale 753</li>
                        <li>depuis Les Herbiers, par la Départementale D9</li>
                        <li>à vélo : Circuit n°20 de la Vendée à Vélo</li>
                        <li>à pied : proche du circuit longeant la Sèvre Nantaise</li>
                    </ul>
                </div>
            </div>
            <p className="mt-l">
                Vous souhaitez plus d’informations sur notre gîte ? N&apos;hésitez pas à nous
                contacter.
            </p>
            <ContactForm />
        </React.Fragment>
    );
}
