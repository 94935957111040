import { useSelector } from 'react-redux';
import HeaderDesktop from './Desktop';
import HeaderMobile from './Mobile';
import './index.css';
import { getScrollTop } from '../../selectors/app';

export default function Header() {
    const scrollTop = useSelector(getScrollTop);
    let headerClasses = 'col header header__shadowed justify-center';
    if (scrollTop > 10) {
        headerClasses += ' header__shadowed-overflowing';
    }
    return (
        <div className={headerClasses}>
            <nav className="header__desktop">
                <HeaderDesktop />
            </nav>
            <nav className="header__mobile">
                <HeaderMobile />
            </nav>
        </div>
    );
}
