import React, { useEffect } from 'react';
import './index.css';
import { Routes, Route } from 'react-router-dom';
import { routes } from '../../services/routes';
import Footer from '../Footer';
import initEvents from '../../services/events';

function MainContainer() {
    useEffect(() => {
        initEvents();
    }, []);
    return (
        <React.Fragment>
            <div className="main-container flex full-width justify-center">
                <div className="main-container__wrapper justify-center">
                    <div className="row" style={{ flexGrow: 1 }}>
                        <div className="content col">
                            <Routes>
                                {routes.map((route, i) => (
                                    <Route key={i} {...route} />
                                ))}
                            </Routes>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    );
}

export default MainContainer;
