import PropTypes from 'prop-types';
import './index.css';

export default function PartnerItem(props) {
    const { name, link, logoSrc, logoBackground } = props.outsideLink;
    return (
        <div className="col partner-card p-m m-s space-between">
            <div className="row justify-center mt-m partner-item__image-container">
                <div className="col justify-center partner-item__image-col full-width">
                    <a href={link} target="_blank" rel="noopener noreferrer" className="">
                        <img
                            alt={name}
                            src={logoSrc}
                            className="partner__link-img"
                            style={{ backgroundColor: logoBackground }}
                        />
                    </a>
                </div>
            </div>
        </div>
    );
}

PartnerItem.propTypes = {
    outsideLink: PropTypes.object.isRequired
};
