import * as Sentry from '@sentry/react';
import packageInfo from '../../package.json';
import { ADMIN_CONTACT_MAIL, CONTACT_MAIL } from '../commons/const';

const DEV_HOSTS = [
    'dev.jardindejany.net',
    'dev-jardindejany.web.app',
    'dev-jardindejany.firebaseapp.com'
];
class AppService {
    constructor() {
        this._isDevEnv = this._computeEnv();
        this._version = packageInfo.version;
        if (this._isDevEnv) {
            console.info('Using dev env');
        }
    }

    get Version() {
        return this._version;
    }

    _computeEnv() {
        const host = window.location.host;
        return DEV_HOSTS.includes(host) || this._checkLocalhost(host);
    }

    _checkLocalhost(host) {
        const match = host.match(/^localhost:[0-9]{1,5}$/);
        return match !== null && match.length !== 0;
    }

    getContactEmail() {
        return this._isDevEnv ? ADMIN_CONTACT_MAIL : CONTACT_MAIL;
    }

    getEnv() {
        return this._isDevEnv ? 'development' : 'production';
    }

    isDevelopment() {
        return this.getEnv() === 'development';
    }

    /**
     * Report an error to sentry
     * @param {Error} error
     * @param {Object} [tags={}]
     * @param {Object} [contexts={}]
     */
    report(error, tags = {}, contexts = {}) {
        Sentry.captureException(error, {
            tags,
            contexts
        });
    }
}
const appService = new AppService();

export default appService;
