import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import ActivityText from './ActivityText';
import ActivityImage from './ActivityImage';

export default function ActivityItem(props) {
    const { activity } = props;
    const inverseOrder = !!props.inverse;

    return (
        <React.Fragment>
            <div className="row mt-m activity-item p-m mt-m">
                <div className="col full-width">
                    <div className={`row space-evenly ${inverseOrder ? 'wrap' : 'wrap-reverse'}`}>
                        {inverseOrder ? <ActivityText activity={activity} /> : null}
                        <ActivityImage activity={activity} />
                        {!inverseOrder ? <ActivityText activity={activity} /> : null}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

ActivityItem.propTypes = {
    activity: PropTypes.object.isRequired,
    inverse: PropTypes.bool
};
