import { useState } from 'react';
import './index.css';
import { menuRoutes } from '../../../services/routes';
import MobileMenuLink from './MobileMenuLink';
import Banner from '../Banner';
import Icon from '../../Icon';

function HeaderMobile() {
    const routes = menuRoutes();
    const [open, setOpen] = useState(false);

    function toggleMenu() {
        setOpen(!open);
    }

    function onNavigate() {
        setOpen(false);
    }

    return (
        <div className="col full-width">
            <Banner />
            <div className="row navigation-mobile__head justify-center mt-m">
                <div
                    className="navigation-mobile__menu-toggle-container row justify-center clickable center full-width"
                    onClick={() => toggleMenu()}
                >
                    <div className="navigation-mobile__menu-icon col justify-center mr-s">
                        {open ? <Icon name="close" /> : <Icon name="menu" />}
                    </div>
                    <div className="col justify-center">Menu</div>
                </div>
            </div>
            <div
                className={`row navigation-mobile__menu ${
                    open ? 'navigation-mobile__opened' : 'navigation-mobile__closed'
                }`}
            >
                <div className="col space-evenly">
                    {routes
                        .filter((route) => route.name)
                        .map((route, i) => (
                            <MobileMenuLink key={i} {...route} onNavigate={onNavigate} />
                        ))}
                </div>
            </div>
        </div>
    );
}

export default HeaderMobile;
