import { useMatch, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function MobileMenuLink({ path, name, onNavigate }) {
    const match = useMatch({ path });
    const isMatching = window.location.hash.replace('#/', '') === '' ? path === '/accueil' : match;

    const navigate = useNavigate();

    const className = `navigation-mobile__menu-item clickable p-xs pl-s full ${
        isMatching ? 'navigation-mobile__menu-item-active' : ''
    }`;

    function clickHandle() {
        navigate(path);
        onNavigate();
    }
    return (
        <div onClick={clickHandle}>
            <div className="flex">
                <div className={className}>{name}</div>
            </div>
        </div>
    );
}

MobileMenuLink.propTypes = {
    path: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onNavigate: PropTypes.func.isRequired
};

export default MobileMenuLink;
