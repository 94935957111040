import React, { useState } from 'react';
import './index.css';
import ImageGallery from 'react-image-gallery';
import Loader from '../../components/Loader';

const IMAGE_BASE_PATH = '/images/gite';
const IMAGES = [
    { name: 'gite-11.jpg', text: 'Entrée' },
    { name: 'gite-06.jpg', text: 'Cuisine' },
    { name: 'gite-07.jpg', text: 'Salon' },
    { name: 'gite-08.jpg', text: 'Salon' },
    { name: 'gite-05.jpg', text: 'Salon porte ouverte' },
    { name: 'gite-01.jpg', text: 'Mezzanine' },
    { name: 'gite-02.jpg', text: 'Mezzanine' },
    { name: 'gite-03.jpg', text: 'Salle de bain' },
    { name: 'gite-09.jpg', text: 'La terrasse' },
    { name: 'gite-13.jpg', text: 'Le jardin' },
    { name: 'gite-15.jpg', text: 'Facade extérieure' },
    { name: 'gite-16.jpg', text: 'Paysage' },
    { name: 'gite-17.jpg', text: 'Vue depuis la terrasse' },
    { name: 'gite-14.jpg', text: 'La maison bleue' },
    { name: 'plaque.jpg', text: 'PLaque' },
    { name: 'other-01.jpg', text: 'Cerisier' },
    { name: 'other-02.jpg', text: 'Chat' },
    { name: 'other-03.jpg', text: 'Rouge Gorge' },
    { name: 'other-04.jpg', text: 'Pierre et ruisseau' },
    { name: 'other-05.jpg', text: 'Panorama chateau' },
    { name: 'other-06.jpg', text: 'Chateau' },
    { name: 'other-07.jpg', text: 'Panorama Tiffauges' },
    { name: 'other-08.jpg', text: 'Sèvre nantaise' },
    { name: 'other-09.jpg', text: 'Sèvre nantaise' }
];
const images = IMAGES.map((i) => ({
    original: `${IMAGE_BASE_PATH}/medium/${i.name}`,
    thumbnail: `${IMAGE_BASE_PATH}/thumbs/${i.name}`,
    fullscreen: `${IMAGE_BASE_PATH}/full/${i.name}`,
    thumbnailWidth: 45,
    originalAlt: i.text,
    originalText: i.text,
    loading: 'eager',
    thumbnailLoading: 'eager'
}));

export default function Gite() {
    const [galleryReady, setGalleryReady] = useState(false);

    function imageLoadHandler() {
        setGalleryReady(true);
    }

    return (
        <React.Fragment>
            <h2 className="mt-s">Le Gîte &quot;Le Jardin de Jany&quot;</h2>
            <h3 className="mt-m">
                Idéalement situé à 25 minutes du Puy du Fou, notre gîte vous accueille toute
                l&apos;année dans un environnement calme et verdoyant.
            </h3>
            <p>
                Il a été conçu et aménagé dans le respect de l&apos;environnement et offre un niveau
                de confort exceptionnel. Au cœur du jardin, son exposition plein ouest lui donne une
                luminosité exceptionnelle. Sa vaste terrasse permet de prendre les repas à
                l&apos;extérieur, à l&apos;ombre d&apos;un grand cèdre.
            </p>
            <div className={`gallery-wrapper ${galleryReady ? '' : 'hide'}`}>
                <ImageGallery
                    items={images}
                    autoPlay={true}
                    showIndex={true}
                    showBullets={false}
                    lazyLoad={true}
                    onImageLoad={imageLoadHandler}
                    slideInterval={7000}
                />
            </div>
            <div className={`gallery-loader col ${galleryReady ? 'hide' : ''}`}>
                <Loader />
            </div>
            <h3 className="mt-m">Le gîte</h3>
            <p>
                Grand séjour lumineux ouvert sur la terrasse et le jardin.
                <br />
                La terrasse permet de prendre les repas à l’extérieur et des transats sont
                disponibles.
                <br />
                La cuisine dispose d’un lave-vaisselle, d’un four multifonctions, d’une plaque
                induction (2 feux), d’un réfrigérateur avec compartiment congélateur, d’une
                bouilloire, une cafetière, d’un grille-pain…
            </p>

            <h3>Couchage</h3>
            <p>
                En mezzanine vous trouverez trois lits (80 cm) dont deux côte à côte.
                <br />
                Dans le séjour, un canapé BZ (1,60 m) grand confort.
                <br />
                La salle d’eau est aménagée avec une douche à l’italienne et les WC sont séparés.
            </p>

            <h3>Animaux domestiques</h3>
            <p>
                Les animaux de compagnie seront acceptés à notre discrétion, et moyennant un
                supplément.
            </p>
            <h3>Certifications</h3>
            <div className="row justify-center mt-s">
                <img
                    src="/images/logos/mdt_2_etoiles.png"
                    alt="Meublé de tourisme deux étoiles"
                    style={{ maxHeight: '100px' }}
                />
            </div>
            <h3>Important</h3>
            <p>
                Le gîte est exclusivement réservé au nombre de personnes prévues au contrat.
                <br />
                Les soirées, fêtes et autres activités bruyantes sont interdites.
                <br />
            </p>
            <h3 className="mt-m">Le gîte est non fumeur</h3>
        </React.Fragment>
    );
}
