import appService from '../services/app';

export default async function sendBasicContactMail(payload) {
    try {
        await fetch(`https://formsubmit.co/ajax/${appService.getContactEmail()}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify(payload)
        });
        return true;
    } catch (e) {
        appService.report(
            new Error('Contact mail failed to be sent'),
            {
                method: 'sendBasicContactMail'
            },
            {
                body: payload,
                fetchedUrl: `https://formsubmit.co/ajax/${appService.getContactEmail()}`
            }
        );
        return false;
    }
}
