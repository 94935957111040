import { CONTACT_MAIL } from '../../commons/const';
import PropTypes from 'prop-types';

export default function MailLink(props) {
    const { className } = props;
    return (
        <a className={className} href={`mailto:${CONTACT_MAIL}`}>
            {CONTACT_MAIL}
        </a>
    );
}

MailLink.propTypes = {
    className: PropTypes.string
};
