export const MARCHE_DE_NOEL = {
    title: 'Marché de noël de Tiffauges',
    text: 'Le marché de Noël du château de Tiffauges, vous propose des animations pour toute la famille sur 2 WE de décembre. Illumination du château et spectacle de projections lumineuses, chants de Noël, spectacle de carillon, spectacle musical, manège, animaux de la ferme et village du Père Noël, vin chaud, pain d’épice et marrons grillés.',
    distance: '500 mètres',
    address: 'Tiffauges',
    mapsLink: 'https://g.page/ChateauTiffauges?share',
    images: [
        {
            src: '/images/activities/marche_de_noel.jpg',
            title: 'Marché de noël de Tiffauges'
        }
    ]
};

export const PDF = {
    title: 'Le Puy du fou',
    distance: '25 minutes',
    text: 'Visitez Le Parc à thème du Puy du Fou, qui vous propose des spectacles grandioses et impressionnants.<br/>Découvrez La Cinéscénie et Les Noces de Feu qui sont des spectacles nocturnes.',
    address: '85590 Les Epesses',
    mapsLink: 'https://goo.gl/maps/dU3NTgNvD7YKkjoj9',
    website: 'https://www.puydufou.com/fr',
    images: [
        {
            src: '/images/activities/cinescenie.jpg',
            title: 'Cinéscénie'
        }
    ]
};

export const CHATEAU_TIFFAUGES = {
    title: 'Château de Tiffauges',
    text: 'À deux pas de notre gite, vous découvrirez le Château de Tiffauges, le plus grand site médiéval de la région (XIIème/XVIème siècle). Appelez communément le Château de Barbe bleue en référence à son plus célèbre résident Gilles de Rais.<br/>Venez participer à des démonstrations de tirs réels avec des machines de guerre médiévales reconstituées, dans le cadre d’un spectacle sur l’art de la guerre.',
    distance: '500 mètres',
    address: 'Tiffauges',
    mapsLink: 'https://g.page/ChateauTiffauges?share',
    website: 'https://www.sitesculturels.vendee.fr/Chateau-de-Tiffauges',
    images: [
        {
            src: '/images/gite/medium/other-05.jpg',
            title: 'Château de Tiffauges'
        }
    ]
};

export const CITE_DES_OISEAUX = {
    title: 'La cité des oiseaux',
    distance: '10 minutes',
    text: 'Grande réserve ornithologique.<br/>Vous pourrez observer une grande quantité d&apos;oiseaux depuis l&apos;observatoire, le belvédère ou tout simplement en arpentant les sentiers de ce site naturel.',
    address: 'Les Boucheries, 85130 Les Landes-Genusson',
    mapsLink: 'https://goo.gl/maps/LSgYgKcbWXYtJx9r7',
    website: 'https://www.sitesnaturels.vendee.fr/citedesoiseaux.html',
    images: [
        {
            src: '/images/activities/cite_oiseaux.jpg',
            title: 'La cité des oiseaux'
        }
    ]
};

export const LOGIS_CHABOTTERIE = {
    title: 'Le logis de la Chabotterie',
    distance: '35 minutes',
    text: 'Haut-lieu de la guerre de Vendée, venez découvrir l’épopée du Général Vendéen Charette.<br/>Histoire, nature, animations et spectacles sont au rendez-vous pour une journée inoubliable.',
    address: 'Saint Sulpice le Verdon, 85260 Montréverd',
    mapsLink: 'https://g.page/Logis-de-la-Chabotterie?share',
    website: 'https://www.sitesculturels.vendee.fr/Logis-de-la-Chabotterie',
    images: [
        {
            src: '/images/activities/logis_de_la_chaboterie.jpg',
            title: 'Le logis de la Chabotterie'
        }
    ]
};

export const PARC_MAULEVRIER = {
    title: 'Parc oriental de Maulévrier',
    distance: '35 minutes',
    text: 'Découvrez le jardin Japonais le plus grand d’Europe, espace de détente et de dépaysement exceptionnel.',
    address: 'Pl. de la Mairie, 49360 Maulevrier',
    mapsLink: 'https://g.page/Parcoriental?share',
    website: 'http://www.parc-oriental.com/',
    images: [
        {
            src: '/images/activities/parc_oriental_maulevrier.jpg',
            title: 'Le parc oriental de Maulévrier'
        }
    ]
};

export const HELLFEST = {
    title: 'Le Hellfest',
    distance: '20 minutes',
    text: 'Le Hellfest, le plus grand festival de musique métal d’Europe.',
    address: 'Rue du Champ Louet, 44190 Clisson',
    mapsLink: 'https://goo.gl/maps/4mViuboHNKdqBoUh6',
    website: 'http://www.hellfest.fr/',
    images: [
        {
            src: '/images/activities/hellfest_2022.jpg',
            title: 'Hellfest'
        }
    ]
};

export const CHATEAU_CLISSON = {
    title: 'Le château de Clisson',
    distance: '20 minutes',
    text: 'Visitez Le château de Clisson, château médiéval dominant la rive gauche de la Sèvre nantaise.',
    address: 'Pl. du Minage, 44190 Clisson',
    mapsLink: 'https://goo.gl/maps/ruGrp2bnQitAmbdW9',
    website: 'https://www.chateau-clisson.fr/',
    images: [
        {
            src: '/images/activities/chateau_clisson.jpg',
            title: 'Le château de Clisson'
        }
    ]
};

export const DOMAINE_GARENNE_LEMOT = {
    title: 'Domaine de la Garenne Lemot',
    distance: '20 minutes',
    text: 'Visitez Le domaine de la Garenne Lemot, un parc aux paysages et à l’architecture italienne situé sur la commune de Gétigné.',
    address: 'Avenue Xavier Rineau, 44190 Gétigné',
    mapsLink: 'https://goo.gl/maps/TGpU4iSJWnsLEGFD9',
    website: 'https://www.domaine-garenne-lemot.fr/',
    images: [
        {
            src: '/images/activities/la_garenne_lemot.jpg',
            title: 'Domaine de la Garenne Lemot'
        }
    ]
};

export const ROUTE_VINS = {
    title: 'La route des vins (muscadet)',
    text: 'Laissez-vous guider le long d’une route aux mille saveurs, pour un voyage au cœur du vignoble des vins du Muscadet, à la rencontre de leurs créateurs, de leurs terres et de leurs histoires.',
    website: 'https://tourisme-loireatlantique.com/routes-des-vins-muscadet/',
    images: [
        {
            src: '/images/activities/route_des_vins_loire_atlantique.jpg',
            title: 'Route des vins de Loire Atlantique'
        }
    ]
};

export const MUSEE_DU_VIN = {
    title: 'Musée du vingnoble nantais',
    distance: '25 minutes',
    text: "Visitez le Musée du Vignoble Nantais, situé sur la commune de Le Pallet (Loire-Atlantique), évoquant l'histoire d'un terroir, les savoir-faire des vignerons nantais et l'évolution des techniques. ",
    address: '82 Rue Pierre Abélard, 44330 Le Pallet',
    mapsLink: 'https://goo.gl/maps/LyocqwAetx1KhhXe9',
    website: 'http://www.musee-vignoble-nantais.eu/',
    images: [
        {
            src: '/images/activities/musee_du_vignoble_nantais.jpg',
            title: 'Musée du vignoble Nantais'
        }
    ]
};

export const TEPACAP_VENDEE = {
    title: 'Tépacap Vendée',
    distance: '25 minutes',
    text: 'Venez passer une journée inoubliable en famille ou entre amis, petits et grands, aventuriers ou compétiteurs, sportifs ou amoureux de la nature, chacun trouvera l’activité qui lui ressemble : accrobranche, paintball, tir à l’arc, parcours d’orientation, challenges ou laser game.',
    address: '9 La Tricherie, 85500 Mesnard-la-Barotière',
    mapsLink: 'https://g.page/Tepacap?share',
    website: 'https://www.tepacapvendee.com/',
    images: [
        {
            src: '/images/activities/tepacap.png',
            title: 'Parc de loisirs Tépacap!'
        }
    ]
};

export const RANDONEE = {
    title: 'Randonnée',
    distance: '300 mètres',
    text: 'Venez découvrir à pied, ou à vélo, des randonnées sur les rives de la Crûme et de la Sèvre Nantaise.<br/>(La Vendée à vélo)',
    website:
        'https://www.vendee.fr/Territoire-et-environnement/Environnement/29844-Les-sentiers-de-randonnee-pedestre-en-Vendee/La-carte-et-les-sentiers',
    images: [
        {
            src: '/images/activities/carte_rando.png',
            title: 'Carte des randonées'
        }
    ]
};

export const TRAIN_MORTAGNE = {
    title: 'Train de Mortagne',
    distance: '16 minutes',
    text: 'Venez découvrir la Vendée à bord du Train à Vapeur avec l’Association Chemin de Fer de la Vendée qui vous propose des balades extraordinaires commentées et animées de Mortagne-sur-Sèvre aux Herbiers et retour.',
    website: 'https://www.vendeetrain.fr/accueil.html',
    address: '2Bis Av. de la Gare, 85290 Mortagne-sur-Sèvre',
    mapsLink: 'https://goo.gl/maps/kFPMEAmi1BbAkrkC8',
    images: [
        {
            src: '/images/activities/train_mortagne.jpeg',
            title: 'Train de Mortagne'
        }
    ]
};

export const ACTIVITIES = [
    CHATEAU_TIFFAUGES,
    MARCHE_DE_NOEL,
    RANDONEE,
    CITE_DES_OISEAUX,
    PDF,
    CHATEAU_CLISSON,
    DOMAINE_GARENNE_LEMOT,
    HELLFEST,
    LOGIS_CHABOTTERIE,
    TRAIN_MORTAGNE,
    PARC_MAULEVRIER,
    TEPACAP_VENDEE,
    MUSEE_DU_VIN,
    ROUTE_VINS
];
export default ACTIVITIES;
