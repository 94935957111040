import Icon from '../Icon';
import PropTypes from 'prop-types';
import './index.css';

export default function InformationModal(props) {
    const { children, type } = props;

    return (
        <div className={`row m-m p-m information-modal information-modal-${type}`}>
            <div className="col justify-center mr-m">
                <div className="row">
                    <Icon name={type} />
                </div>
            </div>
            <div className="col justify-center">{children}</div>
        </div>
    );
}

InformationModal.propTypes = {
    children: PropTypes.any.isRequired,
    type: PropTypes.string.isRequired
};
