import './index.css';
import { Link } from 'react-router-dom';
import appService from '../../services/app';

export default function Footer() {
    const year = new Date().getFullYear();
    return (
        <div className="footer row copyright">
            <div className="col">©&nbsp;{year}&nbsp;Le jardin de Jany</div>
            <div className="col ml-s">|</div>
            <div className="col ml-s">v{appService.Version}</div>
            <div className="col ml-s">|</div>
            <div className="col ml-s">
                <Link to={'/mentions_legales'}>Mentions légales</Link>
            </div>
            <div className="col ml-s">|</div>
            <div className="col ml-s">
                <a title="GDPR-compliant Web Analytics" href="https://clicky.com/101365582">
                    <img
                        alt="Clicky"
                        src="//static.getclicky.com/media/links/badge.gif"
                        border="0"
                    />
                </a>
            </div>
        </div>
    );
}
