import './index.css';
import React, { useEffect, useState } from 'react';
import Icon from '../Icon';
import sendBasicContactMail from '../../services/mail';
import FormInput from '../FormInput';

export default function ContactForm() {
    const [name, setName] = useState('');
    const [isNameValid, setIsNameValid] = useState(false);
    const [mail, setMail] = useState('');
    const [isMailValid, setIsMailValid] = useState(false);
    const [phone, setPhone] = useState('');
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [address, setAddress] = useState('');
    const [isAddressValid, setIsAddressValid] = useState(false);
    const [message, setMessage] = useState('');
    const [isMessageValid, setIsMessageValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFormSent, setIsFormSent] = useState(false);
    const [isFormErrorSentFail, setIsFormErrorSentFail] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        setIsFormValid(
            isNameValid && isMailValid && isPhoneValid && isAddressValid && isMessageValid
        );
    }, [isNameValid, isMailValid, isPhoneValid, isAddressValid, isMessageValid]);

    async function sendForm() {
        if (!isFormValid) {
            return;
        }
        const payload = {
            message,
            mail,
            name,
            adresse: address,
            numero: phone,
            _subject: "Demande d'information"
        };
        setIsLoading(true);
        sendBasicContactMail(payload).then((result) => {
            if (result) {
                setIsFormSent(true);
            } else {
                setIsFormErrorSentFail(true);
            }
            setIsLoading(false);
        });
    }

    if (isFormSent) {
        return (
            <React.Fragment>
                <div className="row success-contact-info">
                    <div className="col justify-center mr-s">
                        <Icon name="check" size="sm" />
                    </div>
                    <div className="col justify-center">Votre demande de contact a été envoyée</div>
                </div>
            </React.Fragment>
        );
    }

    if (isFormErrorSentFail) {
        return (
            <React.Fragment>
                <div className="row error-line">
                    <div className="col justify-center mr-s">
                        <Icon name="warning" size="sm" />
                    </div>
                    <div className="col justify-center">
                        Une erreur est survenue lors de l&apos;envoi du formulaire, veuillez
                        réessayer ultérieurement.
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <h3 className="mt-s">Formulaire de contact</h3>
            <div className="row">
                <div className="col form-col">
                    <FormInput
                        label="Nom et prénom"
                        name="name"
                        validator={(v) => v.length >= 5}
                        errorMessage="Le nom doit contenir au moins 5 caractères."
                        type="input"
                        placeholder="John Doe"
                        onChange={(v) => setName(v)}
                        onValidityChange={(v) => setIsNameValid(v)}
                    />
                    <FormInput
                        label="Email"
                        name="mail"
                        validator={(v) => v.match(/^[A-Z0-9._%+-\\']+@[A-Z0-9.-]+\.[A-Z]{2,7}$/gi)}
                        errorMessage="Cet email semble invalide."
                        type="input"
                        placeholder="john.doe@mail.com"
                        onChange={(v) => setMail(v)}
                        onValidityChange={(v) => setIsMailValid(v)}
                    />
                    <FormInput
                        label="Numéro de téléphone"
                        name="phone"
                        validator={(v) => v.match(/^[0-9+#]{5,}$/gi)}
                        errorMessage="Ce numéro de téléphone semble invalide."
                        type="input"
                        placeholder="0600000000"
                        onChange={(v) => setPhone(v)}
                        onValidityChange={(v) => setIsPhoneValid(v)}
                    />
                    <FormInput
                        label="Adresse"
                        name="address"
                        validator={(v) => v.length >= 20}
                        errorMessage="L'adresse doit contenir au moins 20 caractères"
                        type="input"
                        placeholder="1 rue de la Paix, 75000 Paris"
                        onChange={(v) => setAddress(v)}
                        onValidityChange={(v) => setIsAddressValid(v)}
                    />
                    <FormInput
                        label="Message"
                        name="message"
                        validator={(v) => v.length >= 30}
                        errorMessage="Le message doit contenir au moins 30 caractères"
                        type="textarea"
                        placeholder="Demande d'informations complémentaires"
                        onChange={(v) => setMessage(v)}
                        onValidityChange={(v) => setIsMessageValid(v)}
                    />
                </div>
            </div>
            <div className="row mt-m">
                <button
                    className="contact-form-button"
                    type="submit"
                    onClick={sendForm}
                    disabled={!isFormValid || isLoading}
                >
                    <div className="row justify-center">
                        {isLoading ? (
                            <div className="col justify-center ml-s">
                                <Icon name="autorenew" animate={true} size="sm" />
                            </div>
                        ) : null}
                        <div className="col justify-center">
                            {isLoading ? 'Envoi de ' : 'Envoyer '}la demande
                        </div>
                        {!isLoading ? (
                            <div className="col justify-center ml-s">
                                <Icon name="send" size="sm" />
                            </div>
                        ) : null}
                    </div>
                </button>
            </div>
        </React.Fragment>
    );
}
