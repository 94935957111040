import PropTypes from 'prop-types';
import Icon from '../Icon';

export default function Link(props) {
    const { link, text, icon, className } = props;

    return (
        <a href={link} rel="noopener noreferrer" target="_blank" className={`row ${className}`}>
            {icon ? (
                <div className="col justify-center mr-s">
                    <Icon name={icon} />
                </div>
            ) : null}
            <div className="col justify-center">{text}</div>
        </a>
    );
}

Link.propTypes = {
    link: PropTypes.string.isRequired,
    text: PropTypes.any.isRequired,
    className: PropTypes.string,
    icon: PropTypes.string
};
