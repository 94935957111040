import React from 'react';
import ActivityItem from '../../components/ActivityItem';
import ACTIVITIES from '../../texts/activities';

export default function Activities() {
    return (
        <React.Fragment>
            {ACTIVITIES.map((l, i) => (
                <ActivityItem key={i} activity={l} inverse={i % 2 !== 0} />
            ))}
        </React.Fragment>
    );
}
