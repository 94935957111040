import React from 'react';
import MailLink from '../../components/Misc/MailLink';
import Link from '../../components/Misc/Link';

export default function Legals() {
    return (
        <React.Fragment>
            <h2 className="mt-s">Mentions légales</h2>
            <h3 className="mt-s">1- IDENTIFICATION</h3>
            <p>
                Le Jardin de Jany <br />
                5, Rue de la Crûme - 85130 Tiffauges
                <br />
                Téléphone : 06 98 22 77 20
                <br />
                E-mail : <MailLink />
                <br />
                Site web <a href="https://www.jardindejany.net">https://www.jardindejany.net</a>
                <br />
            </p>
            <h3>2- HEBERGEMENT DU SITE ET RESPONSABILITE EDITORIALE</h3>
            <p>
                Le site Internet <a href="https://www.jardindejany.net">www.jardindejany.net</a> est
                hébergé par :<br />
                SARL OVH
                <br />
                Adresse : 140, quai du Sartel - 59100 ROUBAIX | FRANCE
                <br />
                Tel : 0 899 701 761 - Fax : 03 20 20 09 58
                <br />
                Mail : support@ovh.com
                <br />
                Site : www.ovh.com
                <br />
                Webmaster : <a href="mailto:morgan.denis.60@gmail.com">Denis Morgan</a>
            </p>
            <h3>3- LOI INFORMATIQUE ET LIBERTES</h3>
            <p>
                Conformément à l&apos;article 34 de la loi &quot;Informatique et Libertés&quot;,
                vous disposez d&apos;un droit d&apos;accès, de modification, de rectification et de
                suppression des données qui vous concernent.
                <br />
                Pour exercer ce droit d&apos;accès, adressez-vous à :<br />
                <i>
                    LE JARDIN DE JANY
                    <br />
                    5, Rue de la Crûme
                    <br />
                    85130 Tiffauges
                </i>
                <br />
            </p>
            <h3>4- PROPRIÉTÉ INTELLECTUELLE</h3>
            <p>
                L&apos;ensemble des informations présentes sur ce site peut être imprimé sous
                réserve de :<br />
                - n&apos;utiliser de telles informations qu&apos;à des fins personnelles et en
                aucune manière à des fins commerciales
                <br />
                - reproduire sur toutes copies le copyright du Jardin de Jany
                <br />
                - ne pas modifier de telles informations
                <br />
                Toute autre utilisation non expressément autorisée est strictement interdite sans
                autorisation préalable et écrite de Jany.
            </p>
            <h3>5- RESPONSABILITE</h3>
            <p>
                L&apos;ensemble des informations accessibles via ce site sont fournies en
                l&apos;état. Jany ne donne aucune garantie, explicite ou implicite, et n&apos;assume
                aucune responsabilité relative à l&apos;utilisation de ces informations.
                <br />
                L&apos;utilisateur est seul responsable de l&apos;utilisation de telles
                informations.
                <br />
                Les jardins de Jany se réserve le droit de modifier à tout moment les présentes
                informations notamment en actualisant ce site.
                <br />
                Pour toute remarque sur le fonctionnement du site, vous pouvez nous adresser un
                message en utilisant l&apos;adresse e-mail : <MailLink />
            </p>
            <h3>6- Images et droits d&apos;auteurs</h3>
            <p>
                Voici les mentions relatives aux images et à leur origine :
                <ul>
                    <li>
                        <Link
                            link="https://www.levignobledenantes-tourisme.com/wp-content/uploads/wpetourisme/musee-du-vignoble-le-pallet-44-PCU--5-.jpg"
                            text="Photo du musée du vignoble nantais"
                        />
                    </li>
                    <li>
                        <Link
                            link="https://cdt49.media.tourinsoft.eu/upload/tiffauges-marche-de-noel-1024x688.jpg"
                            text="Marché de noël de Tiffauges"
                        />
                    </li>
                    <li>
                        <Link
                            link="https://www.vendee.fr/content/download/29843/643047/file/Carte%20CG85V3%20290515.pdf"
                            text="Carte des randonnée"
                        />
                    </li>
                    <li>
                        <Link
                            link="https://www.tepacapvendee.com/wp-content/uploads/2021/02/Tepacap-logo-2020-1-1024x1024.png"
                            text="Logo tépacap!"
                        />
                    </li>
                </ul>
            </p>
        </React.Fragment>
    );
}
