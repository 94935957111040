import React from 'react';
import { Link } from 'react-router-dom';
import LinkCmpnt from '../../Misc/Link';
import './index.css';
import Icon from '../../Icon';
import { PRINTABLE_PHONE_NUMBER, PHONE_NUMBER, CONTACT_MAIL } from '../../../commons/const';

export default function Banner() {
    return (
        <React.Fragment>
            <div className="row">
                <div className="col justify-center">
                    <img
                        src="/images/gite/logo-2.png"
                        className="mt-m ml-xs logo-gite"
                        alt="logo"
                    />
                </div>
                <div className="col full-width">
                    <div className="row banner">
                        <h1>
                            <Link to={'/'}>Le Jardin de Jany</Link>
                        </h1>
                    </div>
                    <div className="row sub-title">
                        <span>
                            Gîte à 25 minutes du&nbsp;
                            <a
                                href="https://www.puydufou.com/france/fr"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Puy&nbsp;du&nbsp;Fou
                            </a>
                        </span>
                    </div>
                    <div className="row space-evenly wrap mt-s">
                        <div className="col ml-s mr-s justify-center">
                            <a
                                href="https://goo.gl/maps/G8n6Z25oWErf2qNM9"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="row"
                            >
                                <div className="col">
                                    <Icon name="place" />
                                </div>
                                <div className="ml-xs col justify-center">
                                    5, rue de la Crûme, 85130 Tiffauges
                                </div>
                            </a>
                        </div>
                        <div className="col ml-s mr-s justify-center">
                            <a href={`tel:${PHONE_NUMBER}`} className="row">
                                <div className="col">
                                    <Icon name="phone" />
                                </div>
                                <div className="ml-xs col justify-center">
                                    {PRINTABLE_PHONE_NUMBER}
                                </div>
                            </a>
                        </div>
                        <div className="col ml-s mr-s">
                            <a href={`mailto:${CONTACT_MAIL}`} className="row">
                                <div className="col">
                                    <Icon name="mail" />
                                </div>
                                <div className="ml-xs col justify-center">{CONTACT_MAIL}</div>
                            </a>
                        </div>
                        <div className="col ml-s mr-s">
                            <LinkCmpnt
                                link="https://www.facebook.com/G%C3%AEte-Le-Jardin-de-Jany-106225875289694/"
                                text={'Facebook'}
                                icon="facebook"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
