import PropTypes from 'prop-types';

export default function ActivityImage(props) {
    const { activity } = props;
    if (!activity.images || activity.images.length === 0) {
        return null;
    }
    const [mainImage] = props.activity.images;
    return (
        <div className="col p-s justify-center" style={{ minWidth: '200px', maxWidth: '300px' }}>
            <img src={mainImage.src} alt={mainImage.title} style={{ width: '100%' }} />
        </div>
    );
}

ActivityImage.propTypes = {
    activity: PropTypes.object.isRequired
};
