import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getNameOfPath } from '../../services/routes';
import { BASE_SITE_NAME } from '../../commons/const';

export default function ScrollToTop() {
    const location = useLocation();

    useEffect(() => {
        document.title = `${BASE_SITE_NAME} - ${getNameOfPath(location.pathname)}`;
        const container = document.querySelector('.main-container');
        if (container) {
            container.scrollTo(0, 0);
        }
    }, [location.pathname]);

    return null;
}
