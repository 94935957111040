import PropTypes from 'prop-types';
import './index.css';

export default function Icon(props) {
    const { name, color, size, className, animate } = props;
    const baseClass = 'material-icons';
    const colorClass = color ? `icon-color-${color}` : '';
    const sizeClass = size ? `icon-size-${size}` : '';
    const animateClass = animate ? 'animate' : '';

    return (
        <span
            className={`${baseClass} ${colorClass} ${sizeClass} ${animateClass} ${className || ''}`}
        >
            {name}
        </span>
    );
}

Icon.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    animate: PropTypes.bool
};
