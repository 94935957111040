import { Link, useMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import './index.css';

function DesktopMenuLink({ path, name }) {
    const match = useMatch({ path });
    const origin = window.location.origin;
    const location = window.location.toString().replace(origin, '');
    const isMatching = match || (location === '/' ? path === '/accueil' : location.endsWith(path));

    return (
        <Link to={path}>
            <div className="flex">
                <div className={`navigation__menu-item ${isMatching ? 'active' : ''}`}>{name}</div>
            </div>
        </Link>
    );
}

DesktopMenuLink.propTypes = {
    path: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
};

export default DesktopMenuLink;
