import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import store from './store';
import appService from './services/app';

Sentry.init({
    dsn: 'https://9ccd977a36054b749287739301ba511f@o249197.ingest.sentry.io/4504323276537856',
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    environment: appService.getEnv(),

    // replay
    // This sets the sample rate to be 10%. You may want this to be 100% while in development and sample at a lower rate in production
    replaysSessionSampleRate: appService.isDevelopment() ? 1 : 0.1,
    // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // tracing
    tracesSampleRate: 1.0 // Set tracesSampleRate to 1.0 to capture 100%
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
